/* !main
* @Author: Jeff Shields
* @Date:   2019-01-14 08:53:09
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2020-05-29 11:23:49
*/

// ===========================================================
@import "project/variables";

// ===========================================================
//                    Load themes
// ===========================================================
@import "bs4themes/bootswatch/dist/cyborg/variables";
@import "mythemes/shapiro3/variables";

// Required bootstrap files
@import "project/bs4-required";

@import "project/load-bs4";

// ==========================================================================
// theme classes
// ==========================================================================
@import "bs4themes/bootswatch/dist/cyborg/bootswatch";
@import "mythemes/shapiro3/custom";
