/*
* @Author: Jeff Shields
* @Date:   2020-01-25 07:14:06
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2020-05-29 15:19:53
*/
//
// TODO
//
// => add theme custom classes


// ==========================================================================
// custom classes
// ==========================================================================
.banner {
    min-height: 170px;
    font-family: 'Cinzel', serif;
    @include media-breakpoint-up(sm) {
        min-height: 190px;
    }
}

.shapiro-header {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    color: #fff;
    text-shadow: 3px 3px 3px rgba(100, 100, 100, 1);
    // height: auto;

    a {
        color: #fff;
    }

    .site-title {
        font-size: ceil(($font-size-base * 1.5));
        font-variant: small-caps;
        color: inherit;
        font-family: 'Cinzel', serif;
        font-weight: 400;
        @include media-breakpoint-up(md) {
            font-size: ceil(($font-size-base * 3));
            padding-top: .25em;
        }
       @include media-breakpoint-up(lg) {
            font-size: ceil(($font-size-base * 4));
            padding-top: .75em;
        }
    }

    .tagline {
        font-size: $font-size-base;
        font-weight: 400;
        font-variant: small-caps;
        color: inherit;
        // text-align: right;

        @include media-breakpoint-up(sm) {
            font-size: ceil(($font-size-base * 1.25));
        }
        @include media-breakpoint-up(md) {
            font-size: ceil(($font-size-base * 1.5));

        }
    }

    .contact-info {
        font-variant: small-caps;
        font-size: $font-size-base;

        @include media-breakpoint-up(sm) {
            font-size: ceil(($font-size-base * 1.125));
        }
        @include media-breakpoint-up(md) {
            font-size: ceil(($font-size-base * 1.75));
        }
    }

    .contact-info-email{
        @include media-breakpoint-up(md) {
            position: absolute;
            bottom : 10px ;
        }

    }
}

.logo {
    bottom: 0;
    position: relative;
    max-width: 100%;
    height: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;

}
